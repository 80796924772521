<template>
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="修改管理员信息"
    ></el-page-header>
    <div class="select-container">
      <el-form
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
        v-loading.fullscreen.lock="fullscreenLoading"
      >
        <el-form-item label="管理员ID" required>
          <el-input style="width: 30%" disabled v-model="info.USID"></el-input>
        </el-form-item>
        <el-form-item style="width: 40%" label="名称" required>
          <el-input v-model="info.username"></el-input>
        </el-form-item>
        <el-form-item style="width: 40%" label="登陆账号" required>
          <el-input disabled v-model="info.cardNo"></el-input>
        </el-form-item>
        <el-form-item style="width: 40%" label="密码" required>
          <el-input show-password v-model="info.password"></el-input>
        </el-form-item>
        <el-form-item style="width: 40%" label="权限" required>
          <el-radio v-model="info.verify" label="1">超级管理员</el-radio>
          <el-radio v-model="info.verify" label="2">普通管理员</el-radio>
        </el-form-item>
        <el-form-item style="width: 40%" label="状态" required>
          <el-radio v-model="info.adminState" label="1">正常</el-radio>
          <el-radio v-model="info.adminState" label="2">暂停使用</el-radio>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存修改</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import moment from "moment";
import secret from "@/utils/secret.js";
export default {
  data() {
    return {
      info: "",
      fullscreenLoading: true, // true
      succesShow: false,
      errorShow: false,
    };
  },
  created() {
    const cardNo = this.$route.params.id;
    const single = true;
    this.userKey = secret.Decrypt(localStorage.getItem("user_key"));
    let userKey = this.userKey;
    axios.post(this.GLOBAL.api + "/admin/info", { single, cardNo, userKey }).then((res) => {
      this.info = res.data[0];
      this.fullscreenLoading = false;
    });
  },
  methods: {
    goBack() {
      this.$router.push("/admin/list");
    },
    onSubmit() {
      this.info.updateTime = moment().format("YYYY-MM-DD HH:mm:ss");
      const info = this.info;
      const fix = true;
      let userKey = this.userKey;
      axios.post(this.GLOBAL.api + "/admin/info", { fix, info, userKey }).then((data) => {
        if (data.data.statusCode == 502) {
          this.$message({
            message: "修改失败，请联系管理员",
            type: "warning",
          });
        } else {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.$router.push("/admin/list");
        }
      });
    },
    onCancel() {
      this.$confirm("您还未保存修改, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/admin/list");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 1% 3%;
  position: relative;
}
.box-card {
  width: 100%;
  height: 100%;
}
</style>